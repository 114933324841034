import ParserHelpers, { IStructureToPropsMapResult } from 'utils/parseHelpers';

import { parseBanner } from 'components/Banner/parsers';
import { parseNFButton } from 'components/common/NFButton/parsers';
import { ISubMenuItem } from 'components/SubMenu/SubMenuItem/model';

import { IUmbracoWhereToBuyIncomeData, IUmbracoWhereToBuyParsedData } from './models';

const fieldsForSubMenuLinks = ['mapStoresSubmenuLink', 'relatedElementsListSubmenuLink'];

const createSubMenuLinks = (
  componentsForSubMenuLinks: any[],
  fieldsToFind: string[]
): (ISubMenuItem | null)[] => {
  return componentsForSubMenuLinks
    .map((component) => {
      if (!component) {
        return null;
      }

      const linkData = fieldsToFind.filter((key) => {
        return component[key];
      });

      if (linkData?.length && component[linkData[0]]) {
        const link = component[linkData[0]]?.[0]?.properties;

        return {
          linkToSection: link?.submenuItemAnchor,
          name: link?.submenuItemName,
        };
      }

      return null;
    })
    .filter((item) => Boolean(item));
};

const parseUmbracoWhereToBuyIncomeData = (
  param: IUmbracoWhereToBuyIncomeData
): IUmbracoWhereToBuyParsedData => {
  const data: IStructureToPropsMapResult = ParserHelpers.getStructureToPropsMap(
    param.umbracoWhereToBuy.nodes[0].body
  );
  const relatedElementsListData = data['Related Elements List']?.properties;
  const mapStoresData = data['Map Stores']?.properties;
  const pageSubMenuData = data['Page Sub Menu']?.properties;

  const componentsForSubMenuLinks = [relatedElementsListData, mapStoresData];
  const subMenuLinks: (ISubMenuItem | null)[] = createSubMenuLinks(
    componentsForSubMenuLinks,
    fieldsForSubMenuLinks
  );

  const result: IUmbracoWhereToBuyParsedData = {
    banner: data['Page Banner']
      ? parseBanner(data['Page Banner'].properties, { breadCrumbs: param.breadCrumbs })
      : undefined,
    relatedElementsList: relatedElementsListData
      ? {
          title: relatedElementsListData.relatedElementsListTitle,
          CTABtn: relatedElementsListData.relatedElementsListCTAButton?.length
            ? {
                ...parseNFButton(
                  relatedElementsListData.relatedElementsListCTAButton[0].properties
                ),
              }
            : undefined,
          items: relatedElementsListData.relatedElementsListItems,
          submenuItemAnchor:
            relatedElementsListData.relatedElementsListSubmenuLink?.[0]?.properties
              ?.submenuItemAnchor,
          closeButtonAriaLabel: relatedElementsListData.relatedElementsListCTACloseButtonAriaLabel,
          closeButtonTitle: relatedElementsListData.relatedElementsListCTACloseButtonTitle,
        }
      : undefined,
    mapStores: mapStoresData
      ? {
          title: mapStoresData.mapStoresTitle,
          text: mapStoresData.mapStoresText,
          CTABtn: mapStoresData.mapStoresCTASearchButton?.length
            ? {
                ...parseNFButton(mapStoresData.mapStoresCTASearchButton[0].properties),
              }
            : undefined,
          searchInputTitle: mapStoresData.mapStoresSearchInputTitle,
          closeButtonTitle: mapStoresData.mapStoresCTACloseButtonTitle,
          closeButtonAriaLabel: mapStoresData.mapStoresCTACloseButtonAriaLabel,
          mapImageAlt: mapStoresData.mapStoresMapImageAlt,
          clearButtonAriaLabel: mapStoresData.mapStoresCTAClearButtonAriaLabel,
          mapImage: mapStoresData.mapStoresMapImage,
          searchTarget: mapStoresData.mapStoresSearchTarget,
          searchMapUrl: mapStoresData.mapStoresSearchMapUrl,
          submenuItemAnchor: mapStoresData.mapStoresSubmenuLink?.[0]?.properties?.submenuItemAnchor,
        }
      : undefined,
    pageSubMenu: pageSubMenuData
      ? {
          ariaLabel: pageSubMenuData.pageSubMenuAriaLabel,
          links: subMenuLinks as ISubMenuItem[],
          minItemsNumber: 2,
        }
      : undefined,
  };

  return result;
};

export default parseUmbracoWhereToBuyIncomeData;

import React, { FC, useCallback } from 'react';
import { graphql, PageProps } from 'gatsby';

import NFBodyRenderer, { IExpectedStructures } from 'components/common/NFBodyRenderer';

import Layout from 'components/Layout';
import Banner from 'components/Banner';
import RelatedElementsList from 'components/RelatedElementsList';
import MapStores from 'components/MapStores';
import PageSubMenu from 'components/PageSubMenu';
import { gtmService } from 'services/gtmService';

import { IUmbracoWhereToBuyIncomeData, IUmbracoWhereToBuyParsedData } from './models';
import parseUmbracoWhereToBuyIncomeData from './parsers';

import './WhereToBuyPage.scss';

interface IPropsWhereToBuyPage extends PageProps {
  pageContext: { breadcrumbs: UmbracoTypes.IBreadCrumb[] };
  data: IUmbracoWhereToBuyIncomeData;
}

const ExpectedStructures: IExpectedStructures = {
  'Page Banner': {
    Component: Banner,
    dataKey: 'banner',
  },
  'Related Elements List': {
    Component: RelatedElementsList,
    dataKey: 'relatedElementsList',
  },
  'Map Stores': {
    Component: MapStores,
    dataKey: 'mapStores',
  },
  'Page Sub Menu': {
    Component: PageSubMenu,
    dataKey: 'pageSubMenu',
  },
};

const WhereToBuyPage: FC<IPropsWhereToBuyPage> = (props) => {
  const parsedData: IUmbracoWhereToBuyParsedData = parseUmbracoWhereToBuyIncomeData({
    ...props.data,
    breadCrumbs: props.pageContext.breadcrumbs,
  });

  const {
    seoMetaTitle,
    seoMetaDescription,
    seoMetaKeywords,
    seoExternalHreflangs,
    lang,
  } = props.data.umbracoWhereToBuy.nodes[0];

  const {
    header,
    footer,
    siteSettings,
    covidBanner,
    newsletterPopupDefault,
    newsletterPopupDiscount,
    newsletterPopupFreeDelivery,
    disclaimerPopup,
    searchPath,
    brandSettings,
    legalPopup,
    pageLangs: { nodes: langs },
  } = props.data;

  const retailerClickHandler = useCallback((retailerName: string) => {
    gtmService.emitRetailerClick({}, retailerName);
  }, []);

  return (
    <Layout
      seo={{
        seoMetaTitle,
        seoMetaDescription,
        seoMetaKeywords,
        seoExternalHreflangs,
      }}
      header={header}
      footer={footer}
      siteSettings={siteSettings}
      covidBanner={covidBanner}
      newsletterPopupDefault={newsletterPopupDefault}
      newsletterPopupDiscount={newsletterPopupDiscount}
      newsletterPopupFreeDelivery={newsletterPopupFreeDelivery}
      disclaimerPopup={disclaimerPopup}
      searchPath={searchPath}
      brandSettings={brandSettings}
      legalPopup={legalPopup}
      langSettings={{
        currentLang: lang,
        langs,
      }}
      className="layout-where-to-buy-page"
    >
      <section className="where-to-buy-page">
        <NFBodyRenderer
          bodyStructure={props.data.umbracoWhereToBuy.nodes[0].body}
          expectedStructures={ExpectedStructures}
          parsedData={parsedData}
          additionalBodyProps={{ retailerClickHandler }}
        />
      </section>
    </Layout>
  );
};

export const query = graphql`
  query($lang: String!, $pageBannerImageSize: Int = 1000, $pageIdRegex: String) {
    umbracoWhereToBuy: allUmbracoWhereToBuy(filter: { lang: { eq: $lang } }) {
      nodes {
        ...FragmentSeo
        body {
          properties {
            ...FragmentBanner
            ...FragmentRelatedElementsList
            ...FragmentMapStores
            ...FragmentPageSubMenu
          }
          structure
        }
        umbracoId
        lang
      }
    }
    pageLangs: allUmbracoWhereToBuy(filter: { umbracoId: { regex: $pageIdRegex } }) {
      nodes {
        link
        lang
      }
    }
    ...FragmentCommonCompositions
  }
`;

export default WhereToBuyPage;
